
import { reactive, ref, toRefs } from 'vue-demi';
import { ElMessage } from 'element-plus';
import axios from "../api/axios";
let token = localStorage.getItem("token");

let topId = 0;
let topStatus = ref(0);

export default {
 
  props: ["moduleMsg","modelName","modelListName","defaultShow"],

  setup(props) { 
    // 前缀类型
    let type=(modelName,typeId)=>{
      if (modelName == "news")return ["【内部公告】","【行政通告】","【书籍推荐】","【其他】"][typeId-1]
      if (modelName == "logs")return ["【日报总结】","【周报总结】","【总结汇报】","【其他】"][typeId-1]
      if (modelName == "contract")return ["【未收款】","【已收款】","【部分收】","【已作废】"][typeId-1]
	    if (modelName == "contractAudit")return ["【未收款】","【已收款】","【部分收】","【已作废】"][typeId-1]
      if (modelName == "cust")return ["【钢厂】","【钢厂代理】","【贸易商】","【钢贸服务】"][typeId-1]
    }
    // 签约状态
    let signState = (reader) =>{
	    if(!reader){return "未审核"}
      if(reader == "未读")return"未读"
      if(reader == "已读")return"已读"
      if(props.modelName == "contract" || props.modelNmae == "contractAudit"){
        if(reader == 1)return"新签"
        if(reader == 2)return"续签"
        if(reader == 3)return"赠送合同"
        if(reader == 4)return"作废"
      }
      if(props.modelName == "cust" || props.modelName == "access"|| props.modelListName == 'service'){
        if(reader == 1)return"已签约"
        if(reader == 2)return"未签约"
      }
    }
    // 签约状态的颜色
    let signColor = (reader) =>{
      if(props.modelName == "contract" || props.modelName == "contractAudit"){
        if(reader == 1)return"#F96E1E"
        if(reader == 2)return"#CC0000"
        if(reader == 3)return"#1BBE00"
        if(reader == 4)return"#999"
      }
      if(props.modelName == "cust" || props.modelName == "access"|| props.modelListName == 'service'){
        if(reader == 1)return"#999"
        if(reader == 2)return"#CC0000"
      }
    }
    // 标题
    let title=(titleId)=>{
      if (props.modelName == "news")return  ["公告知识","标题","发布人","时间","读取状态"][titleId]
      if (props.modelName == "logs")return  ["工作汇报","标题","发布人","时间","读取状态"][titleId]
      if (props.modelName == "contract")return ["合同管理","公司名称","业务人员","时间","签约状态"][titleId]
	    if (props.modelName == "contractAudit")return ["合同审核","公司名称","业务人员","时间","审核状态"][titleId]
      if (props.modelName == "cust")return  ["我的客户","公司名称","业务所属","时间","合作意向"][titleId]
      if (props.modelName == "access")return  ["销售记录","公司名称","拥有人","时间","合作意向"][titleId]
      if (props.modelName == "service")return  ["客服记录","公司名称","拥有人","时间","合作意向"][titleId]
    }
    // 详情链接
    let link=(tid)=>{
      if (props.modelName == "news")return {path:'/notice/detail',query:{tid}}
      if (props.modelName == "logs")return {path:'/report/detail',query:{tid}}
      if (props.modelName == "contract")return {path:'/contract/detail',query:{tid}}
      if (props.modelName == "contractAudit")return {path:'/contract/audit',query:{tid}}
      if (props.modelName == "cust")return {path:'/client/detail',query:{tid}}
      if (props.modelName == "access")return {path:'/track/detail',query:{tid}}
      if (props.modelName == "service")return {path:'/service/detail',query:{tid}}
    }
    // 工作汇报岗位
    let job=(id)=>{
      return  ["总经理","事业","经理","员工","站长","主管","客服","合同","人事","副站长",'产品部助理'][id-1]
    }

    function setTop(token,topId){

      axios({
            method:"POST",
            url:"top/set",
            data:{topId,token},
      }).then((data)=>{
            let type = data.data.status == 0?'error':'success';
            ElMessage({
              showClose: true,
              message:`${data.data.msg}`,
              type: `${type}`,
            });
            window.location.reload();
           // clearCacheF();
      })
    };
    // 清除后端缓存 弃用
    // function clearCacheF () {
    //       axios({
    //         method:"POST",
    //         url:"clear/cache",
    //         data:{token}
    //       }).then((data)=>{
    //         if(data.data.status == 1){
    //           //window.location.reload();
    //         }
    //       });
    // }

    let isShows = ref(false);
    let expData = ref([]);
    // 鼠标右键
    let clientX = ref()// 定位
    let clientY = ref()
    let isShowTopMouseBUtton = ref(false);
    const method = reactive({
    rightShow (e,id,topS) {
      clientX.value = e.clientX
      clientY.value = e.clientY
      topId = id;
      topStatus.value = topS;
      isShowTopMouseBUtton.value = true;
    },

    showNo () {
      isShowTopMouseBUtton.value = false;
    },
    topSet () {
      setTop(token,topId);
    },

 })
   // 点击除了当前右键内容外，隐藏
    const buttonRef = ref()
    document.addEventListener('mouseup',(e)=>{
      if(buttonRef.value){
        if(!buttonRef.value.contains(e.target)){ //判断某个元素不是目标元素的子元素 即目标元素以外的区域
        isShowTopMouseBUtton.value =false
        }
      }	
    })

    return {
      props,
      type,
      signState,
      signColor,
      title,
      link,
      job,
      isShowTopMouseBUtton,
      clientX,
      clientY,
      buttonRef,
      setTop,
      topStatus,
      ...toRefs(method),
    };
  },
};







